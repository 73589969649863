import React, { useState } from "react";
import { Link  , useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import { postData, getData } from "./APIServices"; // Import your custom methods
import * as Yup from "yup";
import Cookies from 'js-cookie';
import LoadingSpinner from "./LoadingSpinner";
import config from "../config";

const Login = ({setIsAuthenticated , showToast}) => {
  //const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;
  
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const [credentialData, SetCredentialData] = useState({
    phoneNumber: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    SetCredentialData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const EmptyStringToUndefined = (value, originalValue) =>
    String(originalValue).trim() === "" ? undefined : value;

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .transform(EmptyStringToUndefined)
      .required("Le Numero est requis"), // Required field

    password: Yup.string().required("Le Mot de passe est requis"),
  });

  const handleSubmit = async (e) => {
    try {
      console.log('REACT_APP_API_BASE_URL',REACT_APP_API_BASE_URL)
      setIsLoading(true)
      e.preventDefault();
      //console.log(credentialData);

      await validationSchema.validate(credentialData, { abortEarly: false });
      // Add your login logic here
      const response = await postData(`${REACT_APP_API_BASE_URL}/login` , 
        credentialData
      );
      const data = response.data
      console.log("data from login",data);
      if (data.access_token){
        //0.5 is half day
        Cookies.set('token', data.access_token, { expires: 0.5, path: '/',
          secure: true, // Only sent over HTTPS
        });
      }
      setIsAuthenticated(true)
      setIsError(false)
      navigate('/'); // Redirect to the desired route
      
      //console.log("we already pass")
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        alert(error.errors[0]);
      } else {
        console.error(error);
        alert(error)
      }
      setIsError(true)
    }finally {
      setIsLoading(false);
      //isError ? showToast('Erreur', 'erreur de Connection') : showToast('Connecter avec Succes', 'success');
    }
  };

  return (
    <div className="bg-gray-700 min-h-screen flex items-center justify-center backdrop-blur-sm">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
        {/* Logo Section */}
        <div className="flex justify-center mb-4">
          {/* <img
            src="path/to/your/logo.png" // Replace with your logo path
            alt="Logo"
            className="h-16" // Adjust the height of the logo
          /> */}
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Ridcode</h2>
        </div>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">Connexion</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Nom d'utilisateur:</label>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Entrer votre Numero"
              value={credentialData.phoneNumber}
              onChange={handleInputChange}
              className="w-full border border-gray-300 px-4 py-4 rounded-lg focus:outline-none focus:border-blue-500" // Increased height
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Mot de passe:</label>
            <input
              placeholder="Entrer votre mot de Passe"
              type="password"
              name="password"
              value={credentialData.password}
              onChange={handleInputChange}
              className="w-full border border-gray-300 px-4 py-4 rounded-lg focus:outline-none focus:border-blue-500" // Increased height
              required
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
              disabled={isLoading}
            >
              Se connecter
            </button>
          </div>
        </form>

        {/* Register Prompt */}
        <div className="mt-4 text-center text-gray-600">
          <p>
            Pas de compte?{" "}
            <Link to="/register" className="text-blue-500 underline">Créer un compte</Link>
            .
          </p>
        </div>
      </div>
      {/* The loading spinner will overlay the entire screen */}
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default Login;
