import { useEffect, useState, useContext } from "react";
import "./App.css";

import { BrowserRouter, Route, Routes, Navigate , useNavigate  } from "react-router-dom";
import Cookies from 'js-cookie';

import Navbar from "./components/NavBar";
import Users from "./components/Users";
import DataPlans from "./components/DataPlans";
import Vouchers from "./components/Vouchers";
import DataPlanActivationHistories from "./components/DataPlanActivationHistories";

import Login from "./components/Login";
import Lab from "./components/Lab";
import Register from "./components/Register";
import Hotspots from "./components/Hotspots";
import Toast from "./components/Toast";


function App() {

  
  const [toast, setToast] = useState({
    show: false,
    message: '',
    type: 'success' // or 'error'
  });

  // Function to show toast
  const showToast = (message, type = 'success') => {
    setToast({
      show: true,
      message,
      type
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });
  const [selectedHotspotId, setSelectedHotspotId] = useState(() => {
    return localStorage.getItem('selectedHotspotId');
  });
  const [adminID, setAdminID] = useState(() => {
    return localStorage.getItem('adminID') || '';
  });
  const [adminName, setAdminName] = useState(() => {
    return localStorage.getItem('adminName') || null;
  });

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
    localStorage.setItem('selectedHotspotId', selectedHotspotId);
    localStorage.setItem('adminID', adminID);
    localStorage.setItem('adminName', adminName);
  }, [isAuthenticated, selectedHotspotId, adminID,adminName]);

  const handleLogout = () => {
    setIsAuthenticated(false);
    setSelectedHotspotId(null);
    setAdminID('');
    setAdminName('');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('selectedHotspotId');
    localStorage.removeItem('adminID');
    localStorage.removeItem('adminName');

    Cookies.remove('token', { path: '/' });
    
  };

  const volumeUnits = ["GB", "MB" ,"KB", "Mb"]; // Define array for options
  const timeUnits = ["days", "hours" , "minutes"]; // Define array for options

  // PrivateRoute component to protect routes
  const PrivateRoute = ({ children }) => {
    const token = Cookies.get('token');
    if (!token) {
      return <Navigate to="/login" />;
    }

    //please if there is any error related just delete the following
    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = tokenData.exp * 1000;
      
      if (Date.now() >= expirationTime) {
        handleLogout();
        return <Navigate to="/login" />;
      }
    } catch (error) {
      console.error('Error checking token:', error);
      alert('Error checking token:')
      handleLogout();
      return <Navigate to="/login" />;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  // Function to handle hotspot selection
  const handleHotspotSelect = (hotspotId) => {
    setSelectedHotspotId(hotspotId);
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Login and Register  routes */}
        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} showToast={showToast} />} />
        <Route path="/register" element={<Register />} />

        {/* Hotspots route outside of Navbar */}
        <Route path="/" element={
          <PrivateRoute>
            <Hotspots setAdminName={setAdminName} adminName={adminName} setAdminID={setAdminID} adminID={adminID} onHotspotSelect={handleHotspotSelect} handleLogout={handleLogout}/>
          </PrivateRoute>
        } />

        {/* Other authenticated routes inside Navbar */}
        <Route path="/*" element={
          isAuthenticated ? (
            <Navbar handleLogout = {handleLogout} adminName={adminName}>
              <Routes>
                <Route path="/users" element={<PrivateRoute>
                    {selectedHotspotId ? <Users hotspotId={selectedHotspotId} /> : <Navigate to="/" />}
                  </PrivateRoute>
                } />
                <Route path="/dataPlans" element={
                  <PrivateRoute>
                    {selectedHotspotId ? <DataPlans timeUnits={timeUnits} volumeUnits={volumeUnits}  hotspotId={selectedHotspotId} /> : <Navigate to="/" />}
                  </PrivateRoute>
                } />
                <Route path="/vouchers" element={
                  <PrivateRoute>
                    {selectedHotspotId ? <Vouchers hotspotId={selectedHotspotId} /> : <Navigate to="/" />}
                  </PrivateRoute>
                } />
                <Route path="/planActivationHistories" element={
                  <PrivateRoute>
                    {selectedHotspotId ? <DataPlanActivationHistories hotspotId={selectedHotspotId} /> : <Navigate to="/" />}
                  </PrivateRoute>
                } />
                {/* Redirect undefined routes to the hotspots page */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Navbar>
          ) : (
            <Navigate to="/login" />
          )
        } />
      </Routes>
      {toast.show && (
          <Toast message={toast.message} type={toast.type} onClose={() => setToast({ ...toast, show: false })} />
        )}
    </BrowserRouter>
  );
}

export default App;
