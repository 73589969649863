import { useEffect, useState, useContext  } from "react";
import { Link ,useNavigate} from 'react-router-dom';

import Hotspot from './Hotspot';
import AddHotspot from './AddHospot' 
import { postData, getData } from "./APIServices"; // Import your custom methods
import Cookies from 'js-cookie';
import LoadingSpinner from "./LoadingSpinner";
import config from "../config";


// Main Hotspots component
const Hotspots = ({ onHotspotSelect , setAdminID , adminID , handleLogout,setAdminName,adminName}) => {

  const REACT_APP_API_BASE_URL = config.APP_API_BASE_URL;

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [hotspots, setHotspots] = useState([]);
  const [isAddHotspotModalOpen, setIsAddHotspotModalOpen] = useState(false);
  //const [adminData, setAdminData] = useState({});

  // Mock company name and username
  const companyName = "WIFI ZONE";
  const username = "J";

  useEffect(() => {
    // Simulating fetching hotspots from an API

    const token = Cookies.get('token');

    const fetchHospots = async () => {
      try {
        setIsLoading(true);
        const response = await getData(
          `${REACT_APP_API_BASE_URL}/hotspots` ,{}, token
        );
        console.log(response);
        if( response.data.hotspotsData){
          const hotspotsData = response.data.hotspotsData
          setHotspots(hotspotsData)
          setAdminID(response.data.adminID)
          setAdminName(`${response.data.adminData.firstName} ${response.data.adminData.lastName}`)
          console.log(response.data.adminData.firstName)
        }else{alert('No Hospots in DB')}
        //setVouchersGroups(response.data)
      } catch (error) {
        console.log("at least print error");
        alert(error);
      }finally {
        setIsLoading(false);
      }
    };

    fetchHospots();

  }, []);

  const handleAddHotspot = () => {
    //setHotspots([...hotspots, { ...newHotspot, id: Date.now() }]);
    setIsAddHotspotModalOpen(false);
  };

  const onLogout = () => {
    // Implement logout logic here
    console.log("Logout clicked");
    handleLogout()
    navigate('/login')
  };

  const addHospot = () =>{

  }

  const Header = ({ companyName, username, onLogout }) => {
    return (
      <header className="bg-slate-800 p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">{companyName}</h1>
        <div className="flex items-center space-x-4">
          <span className="text-white">{username}</span>
          <button
            onClick={onLogout}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
          >
            Deconnexion
          </button>
        </div>
      </header>
    );
  };

  return (
    <div className="bg-slate-600 min-h-screen flex flex-col">
      <Header companyName={companyName} username={adminName} onLogout={onLogout} />
      <div className="p-4 flex-grow">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">Hotspots</h2>
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={() => setIsAddHotspotModalOpen(true)}
          >
            Add Hotspot
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {hotspots.map(hotspot => (
            <Hotspot key={hotspot.id} hotspot={hotspot}  onHotspotSelect={onHotspotSelect} />
          ))}
        </div>
        {isAddHotspotModalOpen && (
          <AddHotspot 
            onAdd={handleAddHotspot}
            onClose={() => setIsAddHotspotModalOpen(false)}
            adminID = {adminID}
            setHotspots={setHotspots}
            hotspots = {hotspots}
          />
        )}
      </div>
      {/* The loading spinner will overlay the entire screen */}
      {isLoading && <LoadingSpinner />}
    </div>
  );

  
};

export default Hotspots;