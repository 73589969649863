const RevenuDetails = ({ totalRevenu, revenueCountByAmount }) => {
    return (
      <div className="p-6 bg-gray-800 text-white border border-gray-700 rounded-lg shadow-lg">
        <h3 className="text-lg font-bold mb-4">Détails du Revenu</h3>
        <ul className="text-sm space-y-4">
          <li className="flex justify-between">
            <span>Total Revenu:</span>
            <span>{totalRevenu} FCFA</span>
          </li>
          
          <li>
            <h4 className="font-semibold mt-4">Détails par Forfait:</h4>
            <ul className="ml-4 mt-3 space-y-2">
            {Object.entries(revenueCountByAmount).map(([amount, count], index) => (
                <li
                key={amount}
                className={`flex justify-between p-2 rounded-md shadow-md ${
                    index % 2 === 0 ? 'bg-gray-700' : 'bg-gray-800'
                }`}
                >
                <span>{count} forfait(s) de {amount} FCFA :</span>
                <span>{count * amount} FCFA</span>
                </li>
            ))}
            </ul>

          </li>
        </ul>
      </div>
    );
  };
  
  export default RevenuDetails;
  