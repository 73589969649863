// src/components/RevenuChart.js
import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RevenuChart = ({ revenuByDate }) => {
  // Extract dates and values from the object
  const dates = Object.keys(revenuByDate);
  const revenues = Object.values(revenuByDate);

  // Chart.js configuration
  const data = {
    labels: dates,
    datasets: [
      {
        label: "Revenu par Date (FCFA)",
        data: revenues,
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        pointBackgroundColor: "rgba(75,192,192,1)",
        pointBorderColor: "#fff",
        tension: 0.3, // Smooth curve
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#fff", // Make legend text white
          font: {
            size: 14, // Slightly larger legend text
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw} FCFA`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#fff", // X-axis text color
          font: {
            size: 14, // Slightly larger font for X-axis
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)", // Light grid lines
        },
      },
      y: {
        ticks: {
          color: "#fff", // Y-axis text color
          font: {
            size: 14, // Slightly larger font for Y-axis
          },
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)", // Light grid lines
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 10,
        right: 10,
      },
    },
  };

  return (
    <div className="p-4 bg-gray-900 rounded-lg shadow-lg">
      <Line data={data} options={options} />
    </div>
  );
};

export default RevenuChart;
